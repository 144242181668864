<template>
  <div id="root">
    <el-row align="middle" :gutter="20" type="flex">
      <el-col :span="3">
      卡号
      </el-col>
      <el-col :span="5">
        <el-input v-model="queryData.cardNo" clearable size="small"></el-input>
      </el-col>
      <el-col :span="3">
        用户ID
      </el-col>
      <el-col :span="5">
        <el-input v-model="queryData.userId" clearable size="small"></el-input>
      </el-col>
      <el-col :span="3">
        备注
      </el-col>
      <el-col :span="5">
        <el-input v-model="queryData.remark" clearable size="small"></el-input>
      </el-col>
      <el-col :span="8">
        <el-button type="primary" @click="beginQuery()">查询</el-button>
        <el-button type="warning" @click="queryData={},fillData()">重置</el-button>
      </el-col>
    </el-row>
    <div style="width: 100%;height: 1px;background: #dcdcdc;margin: 20px 0;"/>
    <div>
      <el-button type="primary" @click="showCreate=true">创建卡密</el-button>
    </div>
    <el-table border size="mini" height="100%" style="margin-top: 20px;" :data="list" :highlight-current-row="true"
              :stripe="true">
      <el-table-column align="center" width="120" prop="id" label="ID"></el-table-column>
      <el-table-column align="center" prop="cardNo" label="卡号"></el-table-column>
      <el-table-column align="center" width="90" label="绑定用户ID" prop="userId"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <template v-if="scope.row.createTime">
            {{ formatTime(scope.row.createTime) }}
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="endTime" label="到期时间">
        <template slot-scope="scope">
          <template v-if="scope.row.endTime">
            {{ formatTime(scope.row.endTime) }}
          </template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column align="center" width="90" label="状态" prop="status">
        <template slot-scope="scope">
          <div v-if="scope.row.status==0" style="color: #4cae1b">启用</div>
          <div v-else style="color: #c70b0b">禁用</div>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="remark" label="备注"></el-table-column>
      <el-table-column align="center" width="200" label="操作" prop="status">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="showAddTime=true,addTimeReq.id=scope.row.id">加时</el-button>
          <el-button size="mini" type="danger" v-if="scope.row.status==0">停用</el-button>
          <el-button size="mini" type="danger" v-if="scope.row.status==1">启用</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="showAddTime" title="加时" width="400px" :close-on-click-modal="false">
      <div class="dialog_item">
        <div class="dialog_label">天数：</div>
        <el-input placeholder="数量" type="number" v-model="addTimeReq.days"></el-input>
      </div>
      <div slot="footer">
        <el-button @click="showAddTime=false">取消</el-button>
        <el-button type="primary" @click="addTime">加时</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showCreate" title="创建卡密" width="400px" :close-on-click-modal="false">
      <div>
        <div class="dialog_item">
          <div class="dialog_label">卡密数量：</div>
          <el-input placeholder="数量" type="number" v-model="createCard.count"></el-input>
        </div>

        <div class="dialog_item" style="margin-top: 20px;">
          <div class="dialog_label">天数：</div>
          <el-input placeholder="天数" v-model="createCard.days" type="number"></el-input>
        </div>

        <div class="dialog_item" style="margin-top: 20px;">
          <div class="dialog_label">备注：</div>
          <el-input placeholder="备注" v-model="createCard.remark"></el-input>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="showCreate=false">取消</el-button>
        <el-button type="primary" @click="startCreate">创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CardManager",
  data() {
    return {
      addTimeReq:{
        days:30,
        id:null
      },
      queryData:{
        userId:"",
        remark:"",
        cardNo:""
      },
      showAddTime: false,
      showCreate: false,
      list: [],
      createCard: {
        count: 1,
        days: 30,
        remark: ""
      }
    }
  },
  methods: {
    async beginQuery(){
      try {
        const res = await axios.post(process.env.VUE_APP_BASE_API + "cards/queryCards", this.queryData)
        if (res.data.code != 200) {
          this.$message({
            type: "error",
            message: res.data.message
          })
          return;
        }
        this.list=res.data.data;
      } catch (e) {

      }
    },
    async addTime() {
      try {
        this.showAddTime=false;
        const res = await axios.post(process.env.VUE_APP_BASE_API + "cards/addTime", this.addTimeReq)
        if (res.data.code != 200) {
          this.$message({
            type: "error",
            message: res.data.message
          })
          return;
        }
        this.fillData()
      } catch (e) {

      }
    },
    async startCreate() {
      this.showCreate = false;
      try {
        const res = await axios.post(process.env.VUE_APP_BASE_API + "cards/createCards", this.createCard)
        if (res.data.code != 200) {
          this.$message({
            type: "error",
            message: res.data.message
          })
          return;
        }
        this.fillData()
      } catch (e) {

      }
    },
    formatTime(timestamp) {
      let date = new Date(timestamp);

      function format(str) {
        if (str < 10) {
          return "0" + str
        }
        return str;
      }

      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${format(date.getHours())}:${format(date.getMinutes())}`
    },
    async fillData() {
      try {
        const res = await axios.post(process.env.VUE_APP_BASE_API + "cards/getCards", {})
        if (res.data.code != 200) {
          this.$message({
            type: "error",
            message: res.data.message
          })
          return;
        }
        this.list = res.data.data;
      } catch (e) {

      }

    }
  },
  mounted() {
    this.fillData();
  },
  created() {
  }
}
</script>

<style scoped>
#root {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dialog_item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog_label {
  width: 120px;
}
</style>